<template>
  <v-layout column>
    <v-layout v-if="!disabled && !hideBody" align-center>
      <v-switch
        v-model="data.pregnancy"
        class="zg-text-control mt-2"
        label="Ciąża"
        color="expressive_green"
        hide-details
        :ripple="false"
        inset
      ></v-switch>
      <v-checkbox
        v-model="data.finished"
        class="ml-4 mt-2 zg-text-control"
        label="Zakończona"
        hide-details
        :ripple="false"
        inset
      ></v-checkbox>
      <AutosaveIcon class="ml-3 mt-3" :value="status"></AutosaveIcon>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
    >
      <v-layout class="mb-1">
      <v-switch
        v-model="data.pregnancy"
        class="mt-1"
        label="Ciąża"
        color="expressive_green"
        hide-details
        disabled
        :ripple="false"
        inset
      ></v-switch>
      <v-checkbox
        v-model="data.finished"
        class="ml-4 mt-1"
        label="Zakończona"
        hide-details
        disabled
        :ripple="false"
        inset
      ></v-checkbox>
      </v-layout>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: ""
    }
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          this.$set(this.field, "data", {
            pregnancy: false,
            finished: false,
          });
        }
        return this.field.data;
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    PredefinedRecordMenu: () =>
      import("@/components/menus/PredefinedRecordMenu"),
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onPredefinedInline(data) {
      data.forEach((record) => {
        this.text += `<p>${record}</p>`;
      });
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      console.log("AUTO");
      this.startWatching("dataJson");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.zg-text-control ::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>