<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

const NOT_MODIFIED = 0;
const MODIFIED = 1;
const BEFORE_SYNCING = 2;
const SYNCING = 3;
const SYNCED = 4;

export default {
  timers: {
    hideSynced: { time: 3000, autostart: false, repeat: false },
  },
  props: {
    syncId: {
      type: String,
      default: "id",
    },
    autosave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      watching: undefined,
      status: NOT_MODIFIED,
      watcher: undefined,
      ignoreChanges: false,
    };
  },
  watch: {
    autosave() {
      if (!this.autosave) {
        this.stopWatching();
      }
    },
    statusChangeJson: {
      handler(newValue) {
        const oldStatus = this.status;
        const newStatus = JSON.parse(newValue)[this.syncId];
        if (newStatus == undefined || oldStatus == NOT_MODIFIED) {
          return;
        }
        console.log("OLD", oldStatus);
        console.log("NEW", newStatus);
        if (newStatus == SYNCING && oldStatus == MODIFIED) {
          this.status = SYNCING;
        } else if (newStatus == SYNCED && oldStatus == SYNCING) {
          this.status = SYNCED;
          this.$timer.stop("hideSynced");
          this.$timer.start("hideSynced");
        } else if (newStatus == NOT_MODIFIED && newStatus != oldStatus) {
          this.status = NOT_MODIFIED;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("autosave", ["getStatus", "statusChange"]),
    //Vue doesn't detect changes if watched value is object.
    //The only workaround is to change object to JSON string.
    //Workaround for $watch:
    statusChangeJson() {
      return JSON.stringify(this.statusChange);
    },
    icon() {
      switch (this.status) {
        case SYNCING:
          return "fas fa-spinner";
        case NOT_MODIFIED:
          return "";
        case MODIFIED:
          return "";
        case BEFORE_SYNCING:
          return "";
        case SYNCED:
          return "fas fa-check-circle";
      }
      return "";
    },
  },
  methods: {
    ...mapActions("autosave", ["modified"]),
    startWatching(propertyName) {
      this.watching = propertyName;
      this.watcher = this.$watch(propertyName, (newValue, oldValue) => {
        const isEqual = _.isEqual(oldValue, newValue);
        if (!isEqual || this.ignoreChanges) {
          this.status = MODIFIED;
          console.log("Changed from: ", oldValue);
          console.log("Changed to: ", newValue);
          console.log("Equals: ", isEqual);
          this.modified(this.syncId);
        }
      });
    },
    stopWatching() {
      this.watcher();
    },
    hideSynced() {
      if (this.status == SYNCED) {
        this.status = NOT_MODIFIED;
      }
    },
  },
};
</script>